<template>
  <div>
  <TosListVuetify serviceName="registry" entityName="parameters" :headers="headers" >
    <template #customFilters="slotProps">
      <v-container fluid>
       <v-row>         
          <v-col cols="3">
            <v-text-field  :label="tLabel('Key')"  v-model="slotProps.data.keyStartsWith" />
          </v-col>
          <v-col cols="3">           
            <v-text-field  :label="tLabel('Value')" v-model="slotProps.data.value" />
          </v-col>
          <v-col cols="3">           
            <v-text-field  :label="tLabel('Description')" v-model="slotProps.data.descriptionLike" />
          </v-col>
       </v-row>
      </v-container>
    </template>
    <template v-slot:editF="slotProps">
        <v-container fluid>
          <v-row>           
            <v-col>
              <v-text-field :label="tLabel('Key')"  v-model="slotProps.data.key" :rules="[rules.required]" :error-messages="slotProps.errors.key" />
            </v-col>
            <v-col>
              <v-text-field :label="tLabel('Value')"  v-model="slotProps.data.value" :error-messages="slotProps.errors.value" />
            </v-col>
            <v-col>
              <v-text-field :label="tLabel('Description')"  v-model="slotProps.data.description" :error-messages="slotProps.errors.description" />
            </v-col>
          </v-row>
       </v-container>
    </template>
  </TosListVuetify>

</div>

</template>


<script>
import TosListVuetify from '@/components/anagrafiche/TosListVuetify';
import FormatsMixins from "@/mixins/FormatsMixins";

export default {
  name: "UnitTypes",
  data() {
    return {
      sysSubtypes: [],
      countTypes: [],
      headers: [
        { text: "", value: "action" },
        { text: `${this.tLabel('Key')}`, value: "key" },
        { text: `${this.tLabel('Value')}`, value: "value" },
        { text: `${this.tLabel('Description')}`, value: "description" },
      ],
    };
  },
  mounted(){
  },
  
  methods: {
  },
  components: {TosListVuetify},
  mixins: [FormatsMixins],
};
</script>
